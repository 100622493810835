import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import {useValidation} from "react-class-validator";
import {UserValidator} from '../Validators/userValidator'
import {postRequest} from "../Store/Requests/postRequest";
import {postImgRequest} from "../Store/Requests/postRequest";
import {connect} from "react-redux";
import {requestInterceptor} from "../interceptor/interceptor";
import { generateString } from "../function/stringFunction";
import { useParams } from "react-router-dom";
import { formatDate } from "../function/dateFunction";

const steps = ["Création article"];

function CreateArticle(props) {
  const {id} = useParams()
  const [activeStep,
    setActiveStep] = React.useState(0);
  const [userValide,
    userErrors] = useValidation(UserValidator)
  const [skipped,
    setSkipped] = React.useState(new Set());
  const [userValues,
    setUserValues] = React.useState({})
  const [articleValues,
    setArticleValues] = React.useState({})
  const [articleErrors,
    setArticleErrors] = React.useState('')
  const isStepOptional = step => {
    return step === 1;
  };
  const submit = () => {
    const articleData = {
      "title": articleValues.title,
      "text": articleValues.texte,
      //"source_url": articleValues.source_url
    }

    postRequest(articleData, 'wp-posts', props.auth.access_token).then((data)=>{
      if(requestInterceptor(data.data,props.notistack, (articleData?'L\'article a été crée avec succès':undefined))){
          const action = {type:'ADD_ARTICLE_ONE', value:data.data}  
          props.dispatch(action)
          props.setOpen(false)
          setArticleValues({})

          const formData = new FormData();
          formData.append('file',articleValues.file);
          postImgRequest(formData,`image-upload/wp-post/${data.data.id}`, props.auth.access_token).then((data)=>{
            window.location.reload()
          })
          
        }
    })
  }

   const handleArticleInputChange = e => {
    setArticleValues({
      ...articleValues,
      [e.target.name]: e.target.value
    })
  }
  const inputFileOnChange = e => {
    setArticleValues({
      ...articleValues,
      [e.target.name]: e.target.files[0]
    })
  }
  const handleBack = () => {
    props.setOpen(false)
  };


  return (
    <Box sx={{
      width: "100%"
    }}>
      <React.Fragment>
            <div
              style
              ={{
              display: "flex",
              flexDirection: "column",
              marginTop: 20
            }}>
              <TextField
                sx={{
                backgroundColor: "#F9FDFF"
              }}
                id="outlined-basic"
                type='string'
                name='title'
                value={articleValues.title}
                onChange={handleArticleInputChange}
                label="Entrez le titre de l'article"
                variant="outlined"/>
              <Typography
                sx={{
                color: 'red',
                textAlign: 'center',
                mb: 2,
                fontSize: 12
              }}>
                {articleErrors.name}
              </Typography>
              <TextField
                sx={{
                backgroundColor: "#F9FDFF"
              }}
                id="outlined-basic"
                type='string'
                name='texte'
                value={articleValues.texte}
                onChange={handleArticleInputChange}
                label="Entrez le contenu de l'article"
                variant="outlined"/>
              <Typography
                sx={{
                color: 'red',
                textAlign: 'center',
                mb: 2,
                fontSize: 12
              }}>
                {articleErrors.texte}
              </Typography>
              <input 
                sx={{
                backgroundColor: "#F9FDFF"
              }}
                id="outlined-basic"
                type='file'
                name='file'
                value={articleValues.source_url}
                onChange={inputFileOnChange}
                label="Image"
                variant="outlined"/>
              <Typography
                sx={{
                color: 'red',
                textAlign: 'center',
                mb: 2,
                fontSize: 12
              }}>
                {articleErrors.source_url}
              </Typography>
            </div>
        <Box
          sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2
        }}>
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleBack}
            sx={{
            mr: 1
          }}>
            Annuler
          </Button>
          <Box sx={{
            flex: "1 1 auto"
          }}/>
          <Button variant="contained" onClick={submit}>
          Enregistrer
          </Button>
        </Box>
      </React.Fragment>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {notistack: state.notification.notistack, auth: state.auth, article:state.article};
};

export default connect(mapStateToProps)(CreateArticle)