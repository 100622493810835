const filterTable = (array, element, value)=>{
    if(!(array&&array.length)){
        return []
    }
    const result = array.filter((item)=>item[element]==value)
    return result
}

const sortTable = (array, element,type, order)=>{
    if(!(array&&array[0])){
        return []
    }
    if(!element){
        return array
    }
    for(let x in array){
        for(let y in array){
            if(type=='data'){
                array[x][element]=new Date(array[x][element])
                array[y][element]=new Date(array[y][element])
            }
            if(order=="DSC"){
                if(array[x][element]<array[y][element]){
                    let pivot = array[y]
                    array[y] = array[x]
                    array[x] = pivot
                }
            }else{
                if(array[x][element]>array[y][element]){
                    let pivot = array[y]
                    array[y] = array[x]
                    array[x] = pivot
                }
            }
        }
    }

    return array
}

const searchInArray=(array, element, value)=>{
    if(!Array.isArray(array)){
        return []
    }
    if(Array.isArray(element)){
        const result = array.filter((item)=> (element.map((item2)=>item[item2])).join(' ').search(new RegExp(value, 'i'))!=-1)
        return result
    }else if(element) {
        const result = array.filter((item)=> item.element&&item.element.search(new RegExp(value, 'i'))!=-1)
        return  result
    }
    else{
        return array
    }
}

const pagination = (array, page, itemByPage)=>{
    if(!Array.isArray(array)){
        return []
    }
    const arrayPagination = array.filter((item, index)=> {
        if(index>=(((page-1) * itemByPage)) && index<=(((page-1) * itemByPage)-1 + itemByPage)){
            return item
        }
    })
    console.log(arrayPagination)
    return arrayPagination
}

const createTableByItem=(array, item)=>{
    if(!Array.isArray(array)){
        return []
    }
    
    return array.map((element)=>element[item])
}
export { filterTable , sortTable, searchInArray, pagination, createTableByItem}