import { useEffect, useState } from "react"
import PanelList from "./panelList"
import PanelModal from "./panelModal"

export default function PanelMain(props){
    const [open, setOpen] = useState(false)
    const [client, setClient] = useState(null) 
    useEffect(()=>{
        if(client){
            setOpen(true)
        }else{
            setOpen(false)
        }
    }, [client])
    return <>
    <PanelList setOpen={setOpen} setClient={setClient}   ></PanelList>
    <PanelModal open={open} setOpen={setOpen} client={client} setClient={setClient} ></PanelModal>
    </>
}