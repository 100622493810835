const initialState=[]

const toggleArticle =(state=initialState, action)=>{
    let nextState
    switch (action.type) {
        case 'ADD_ARTICLE':
            nextState=[...action.value]
            break;
        case 'ADD_ARTICLE_ONE':
            nextState=[...state,{...action.value}]
            break;
        case 'DELETE_ARTICLE':
            nextState = state.filter((item)=>item.id!=action.value.id)
            break;
        default:
            return state
            break;
    }
    return nextState || state
}


export {toggleArticle}