import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import ListeUserByID from './ListeUserByID';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props}/>;
});

export default function AlertePopUp(props) {
  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description">
        <div style ={{padding:60}}>
          <ListeUserByID setOpen={props.setOpen} data={props.data} ></ListeUserByID>
        </div>
      </Dialog>
    </div>
  );
}