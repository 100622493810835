import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import {useValidation} from "react-class-validator";
import {UserValidator} from '../Validators/userValidator'
import {postRequest} from "../Store/Requests/postRequest";
import {connect} from "react-redux";
import {requestInterceptor} from "../interceptor/interceptor";
import { generateString } from "../function/stringFunction";
import { useParams } from "react-router-dom";

const steps = ["Création de compte", "Attribution de panneau"];

function CreatePanel(props) {
  const lengthOfPassword =10
  const {id} = useParams()
  const [activeStep,
    setActiveStep] = React.useState(0);
  const [userValide,
    userErrors] = useValidation(UserValidator)
  const [skipped,
    setSkipped] = React.useState(new Set());
  const [userValues,
    setUserValues] = React.useState({})
  const [panelValues,
    setPanelValues] = React.useState({})
  const [panelErrors,
    setPanelErrors] = React.useState('')
  const isStepOptional = step => {
    return step === 1;
  };
  React.useEffect(()=>{
    if(props.client){

    }
  }, [props.client])
  const submit = () => {
    const userId = props.client.userId
    if(!userId){
      return
    }
    if(userId){
      for (let i = 0; i < panelValues.number; i++) {
        const panelData = {
          "name": `Mon panneau test ${i}`,
          "clientId": userId,
          "isInstalled": false,
          "orderId": "AZERTYUIOP1234",
          "services": [],
          "places": 0,
          "location": {},
          "description": "",
          "parkingType": "véhicule",
          "address": ""
        }
        postRequest(panelData, 'panels', props.auth.access_token).then((data)=>{
          console.log("panelData",panelData)
            if(requestInterceptor(data.data,props.notistack, (i==panelValues.number-1?'Les panneaux ont été crée avec succès':undefined))){
              const action = {type:'ADD_PANEL_ONE', value:data.data}
              props.dispatch(action)
              console.log("data.data",data.data)
              console.log("action",action)
              props.setOpen(false)
              setUserValues({number:null})
              setPanelValues({})
            }
        })
      }
    }
  }

   const handlePanelInputChange = e => {
    setPanelValues({
      ...panelValues,
      [e.target.name]: e.target.value
    })
  }
  const handleBack = () => {
    props.setOpen(false)
  };


  return (
    <Box sx={{
      width: "100%"
    }}>
      <React.Fragment>
            <div
              style
              ={{
              display: "flex",
              flexDirection: "column",
              marginTop: 20
            }}>
              <TextField
                sx={{
                backgroundColor: "#F9FDFF"
              }}
                id="outlined-basic"
                type='number'
                name='number'
                value={panelValues.number}
                onChange={handlePanelInputChange}
                label="Entrez le nombre de panneau "
                variant="outlined"/>
              <Typography
                sx={{
                color: 'red',
                textAlign: 'center',
                mb: 2,
                fontSize: 12
              }}>
                {panelErrors}
              </Typography>
            </div>
        <Box
          sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2
        }}>
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleBack}
            sx={{
            mr: 1
          }}>
            Annuler
          </Button>
          <Box sx={{
            flex: "1 1 auto"
          }}/>
          <Button variant="contained" onClick={submit}>
          Enregistrer
          </Button>
        </Box>
      </React.Fragment>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {notistack: state.notification.notistack, auth: state.auth, panel:state.panel};
};

export default connect(mapStateToProps)(CreatePanel)