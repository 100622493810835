import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreComponent from "./moreComponent";
import { connect } from "react-redux";
import { formatDate } from "../function/dateFunction";
import { getAllList } from "../Store/Requests/getRequest";
import { requestInterceptor } from "../interceptor/interceptor";
import { useEffect, useState } from "react";
import { filterTable, searchInArray, sortTable } from "../function/arrayFunction";
import { useParams } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
function PanelList(props) {
  const [init, setInit]= useState(false)
  const {id} = useParams()
  const [panel, setPanel] = useState([])
  const [search, setSearch] = useState('')
  useEffect(()=>{
    if(props.client){
      if(search){
        setPanel(searchInArray(sortTable(filterTable(props.panel, 'clientId', props.client.userId), 'createdAt', 'date'), ['name', 'address'], search))
      }else{
        setPanel(sortTable(filterTable(props.panel, 'clientId', props.client.userId), 'createdAt', 'date'))
      }
     
    }else{
      setPanel([])
    }
  }, [props.client, props.panel, search])
    useEffect(()=>{
        if(!props.user.length){
          getAllList('users', props.auth.access_token).then((data)=>{
            console.log ('=====================>',data)
            if(requestInterceptor(data, props.notistack)){
                const action = {type:'ADD_USER', value:sortTable(filterTable(data.data, 'role', 'TECHNICIAN'),'createdAt', 'type',)}
                props.dispatch(action)
            }
          })
        }
    }, [init])
  return (
    <div style={{width:'80vw', minHeight:'80vh'}} >
      <Grid container spacing={2}>
        <Grid item xs={8}>
        <Typography
        sx={{ marginLeft: 3, marginTop: 5 }}
        variant="h5"
        component="div"
        gutterBottom
      >
        Liste des panneaux
      </Typography>
        </Grid>
        <Grid item xs={1}>
        <IconButton sx={{marginLeft:35, marginTop:'auto'}} onClick={()=>props.setOpen(false)} >
          <CancelIcon sx={{color:'red'}} ></CancelIcon>
        </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} md={8}>
          <TextField
            sx={{ marginLeft: 3, width: "50%" }}
            id="outlined-basic"
            onChange={(e)=>setSearch(e.target.value)}
            label="Rechercher un panneau"
            variant="outlined"
          />
        </Grid>
        <Grid sx={{ textAlign: "right" }} item xs={6} md={4}>
          {id&&<Button
            onClick={()=>props.setOpen(true)}
            variant="contained"
            sx={{ height: "60%", width: "60%", marginRight: 2 }}
          >
            Ajouter un PANNEAU
          </Button>}
        </Grid>
      </Grid>
      <TableContainer component={Paper} sx ={{width:"97%", margin:'0 auto', marginTop:3}}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>N°</TableCell>
              <TableCell>ADRESSE</TableCell>
              <TableCell>STATUS</TableCell>
              <TableCell>TYPE DE PLACE</TableCell>
              <TableCell>TYPE DE STATIONNEMENT</TableCell>
              <TableCell>DATE DE CREATION</TableCell>
              {/* <TableCell>ACTION</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              panel.map((item, index)=>
                <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {index+1}
              </TableCell>
              <TableCell>{item.address?item.address:'Non localisé'}</TableCell>
              <TableCell>{item.isInstalled?'Installé':'Non installé'}</TableCell>
              <TableCell>{item.placeType=='PUBLIC'?'publique':'privée'}</TableCell>
              <TableCell>{item.parkingType}</TableCell>
              <TableCell>{formatDate(item.createdAt)}</TableCell>
              {/* <TableCell><MoreComponent setClient={props.setClient} data={item} ></MoreComponent></TableCell> */}
            </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {notistack: state.notification.notistack, auth: state.auth, panel:state.panel, user:state.user};
};

export default connect(mapStateToProps)(PanelList)