import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { postRequest } from '../Store/Requests/postRequest';
import { deleteRequest } from '../Store/Requests/deleteRequest';
import { requestInterceptor } from '../interceptor/interceptor';
function MoreComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetPassword =()=>{
    postRequest({email:props.data.email}, 'auth/password/reset/generated', props.auth.access_token).then((data)=>{
      if(requestInterceptor(data, props.notistack, "Le mot de passe a été modifié avec succès")){

      }
    })
  }

  const deleteUser= ()=>{
   
    deleteRequest(props.data.userId, 'users', props.access_token).then((data)=>{
      if(requestInterceptor(data, props.notistack,"Le compte a été supprimé avec succès" )){
        const action= {type:'DELETE_USER', value:{id:props.data.userId}}
        props.dispatch(action)
      }
    })
  }

  return (
    <div>
       <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={()=>{handleClose(); props.setClient(props.data); props.setClientOpen(true)}}>Modifier</MenuItem>
        <MenuItem onClick={()=>{handleClose(); props.createNotificationPopUp('Réinitialisation de mot de passe', `Voulez-vous réinitialiser le mot de passe de ${props.data.firstName} ${props.data.lastName}`, 'OUI', resetPassword)}}>Réinitialiser le mot de passe</MenuItem>
        <MenuItem onClick={()=>{handleClose(); props.createNotificationPopUp('Suppression du compte et des panneaux', `Voulez-vous supprimer le compte de ${props.data.firstName} ${props.data.lastName}`, 'OUI', deleteUser)}}>Supprimer</MenuItem>
        <MenuItem onClick={()=>{handleClose(); props.setModalOpen(true);  props.setClient(props.data)}}>Ajouter un panneau</MenuItem>
        <MenuItem onClick={()=>{handleClose(); props.setpopUpOpen(true);  props.setClient(props.data)}}>Liste des panneaux</MenuItem>
      </Menu>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {createNotificationPopUp: state.notification.createNotificationPopUp, auth:state.auth, notistack: state.notification.notistack};
};

export default connect(mapStateToProps)(MoreComponent)