
import { objectIsEmpty } from "../function/objectFunction"

const requestInterceptor = (data, notification, message, exceptCode = []) => {
  let errorFind = false
  let notificatioIsFunction =true
  if (typeof notification !== 'function') {
    notificatioIsFunction= false
  }
  // if (objectIsEmpty(data)) {
  //   return false
  // }
  switch (data.statusCode) {
    case 401:
      if (notificatioIsFunction&&exceptCode.indexOf(401) === -1) {
        notification('Veuillez-vous authentifier', {variant: 'error'})
      }
      errorFind = true
      break;
    case 404:
      if (notificatioIsFunction&&exceptCode.indexOf(401) === -1) {
        notification(data.data.message
          ? data.data.message
          : 'Le serveur ne peut pas donner suite à votre requête', {variant: 'error'})
      }
      errorFind = true
      break;
    case 409:
      if (notificatioIsFunction&&exceptCode.indexOf(401) ===-1) {
        notification(data.data.message, {variant: 'error'})
      }
      errorFind = true
      break;
    case 400:
      if (notificatioIsFunction&&exceptCode.indexOf(400) === -1) {
        notification('Les données envoyées au serveur ne sont pas correctes', {variant: 'error'})
      }
      errorFind = true
      break;
    case 500:
      if (notificatioIsFunction&&exceptCode.indexOf(500) === -1) {
        notification('Le seveur n\'a pas puis donner de reponse à votre requête. Veullez contacter l\'' +
            'admin', {variant: 'error'})
      }
      errorFind = true
      break;
    case 1000:
      if (notificatioIsFunction&&exceptCode.indexOf(100) === -1) {
        notification(`Impossible de communiquer avec le serveur. Veullez verifier votre connexion internet`, {variant: 'error'})
      }
      errorFind = true
    default:
      errorFind = false
      if (message) {
        notification(message, {variant: 'success'})
      }
      break;
  }
  return !errorFind
}

export {requestInterceptor}