const initialState=[]

const togglePanel =(state=initialState, action)=>{
    let nextState
    switch (action.type) {
        case 'ADD_PANEL':
            nextState=[...action.value]
            break;
        case 'ADD_PANEL_ONE':
            nextState=[{...action.value},...state]
            break;
        default:
            return state
            break;
    }
    return nextState || state
}


export {togglePanel}