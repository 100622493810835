import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {TrainRounded} from '@mui/icons-material';
import {connect} from 'react-redux';

const BootstrapDialog = styled(Dialog)(({theme}) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const BootstrapDialogTitle = (props) => {
  const {
    children,
    onClose,
    ...other
  } = props;

  return (
    <DialogTitle sx={{
      m: 0,
      p: 2
    }} {...other}>
      {children}
      {onClose
        ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}>
            <CloseIcon/>
          </IconButton>
        )
        : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};
let buttonFunction = ()=>{}
function CustomizedDialogs(props) {
  const [open,
    setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setOpen(newValue);
    }
  };

  const [title,
    setTitle] = React.useState('')
  const [message,
    setMessage] = React.useState('')
  const [init,
    SetInit] = React.useState('')
  const [buttonText,
    setButtonText] = React.useState('')
  const [buttonFunctionParams,
    setButtonFunctionParams] = React.useState({})
  const createNotificationPopUp = (title, message, button, functionButton, params) => {
    setTitle(title)
    setMessage(message)
    setOpen(true)
    setButtonText(button)
    setButtonFunctionParams(params)
    buttonFunction=functionButton
  }
  React.useEffect(() => {
    const action = {
      type: 'CREATE_NOTIFICATION_POP_UP',
      value: createNotificationPopUp
    }
    props.dispatch(action)
  }, [init])
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        sx={{
        '& .MuiDialog-paper': {
          width: '80%',
          maxHeight: 435
        }
      }}
        maxWidth="xs"
        open={open}>
        <DialogContent>
          <p
            style={{
            fontSize: 20,
            textAlign: 'center',
            marginTop: 30
          }}>{title}</p>
          <p
            style={{
            width: '80%',
            textAlign: 'center',
            margin: 'auto',
            marginBottom: 30
          }}>{message}</p>
        </DialogContent>
        <DialogActions>
          <div
            style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
          }}>
            <Button variant="outlined" sx={{mx:3}} onClick={()=>{setOpen(false)}}>Annuler</Button>
            <Button variant="contained" sx={{mx:3}}  onClick={() => {
              if (buttonFunction) {
                buttonFunction(buttonFunctionParams);
              }
              setOpen(false)
            }}>{buttonText}</Button>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {createNotificationPopUp: state.notification.createNotificationPopUp};
};

export default connect(mapStateToProps)(CustomizedDialogs);