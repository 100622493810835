import React, { Component, useEffect, useState } from 'react';
import { GoogleMap, InfoWindow, LoadScript, Marker, MarkerClusterer } from '@react-google-maps/api';
import { connect } from 'react-redux';
import { createTableByItem, filterTable } from '../../function/arrayFunction';

const mapContainerStyle = {
  height: '400px',
  width: '800px',
}

const center = { lat:48.8588336, lng: 2.2769949  }

const options = {
  imageExtension:'png',
  imagePath:
    './images/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
}



const MapWithMarkerClusterer = (props) => {
  const [load, setLoad] = useState(false)
  const [infoLoad, setInfoLoad] = useState(false)
  const [activeMarker, setActiveMarker] = useState(null)
  useEffect(()=>{
      setTimeout(()=>setLoad(true), 3000)
  }, [])

  useEffect(()=>{
    if(props.panel.length){
      setTimeout(()=>setInfoLoad(true), 2000)
    }
  },[props.panel])
  const options = {
    imagePath:
      'images/m', // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
  }

  const clusterStyles = [
    {
        height: 50, textColor: '#ffffff', width: 50,
        url: 'data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" height="50" width="50" %3E%3Ccircle cx="25" cy="25" r="20" stroke="black" stroke-width="1" fill="rgba(9, 95, 169, 1)" /%3E%3C/svg%3E',
    }
];

  return (
      <GoogleMap id='marker-example' mapContainerStyle={mapContainerStyle} zoom={8} center={center}
      // onLoad={(item)=>alert(JSON.stringify(item))}
      >
      {load&&<MarkerClusterer averageCenter	
      enableRetinaIcons	
      	
      gridSize={40}		
      minimumClusterSize={4}		
      maxZoom={15}		
      zoomOnClick={true}
      options={{
        averageCenter: true,
        styles: clusterStyles,
      }}	
        >
          {(clusterer) =>
            props.panel.map((item, index) => (
              item.location &&<Marker 
              onClick={()=>setActiveMarker(index)}
              icon={{
                path:
                  "M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z",
                fillColor: item.placeType=='PUBLIC'?'#93B511':'#2280C8',
                fillOpacity: 0.9,
                scale: 0.08,
                strokeColor: item.placeType=='PUBLIC'?'black':'black',
                strokeWeight: 0.5,
              }} key={item.location.coordinates[0] + item.location.coordinates[1]} position = {{ lat:item.location.coordinates[1], lng:item.location.coordinates[0]}} clusterer={clusterer} >
                  {activeMarker==index&&infoLoad&&<InfoWindow>
                <div style={{
                  borderRadius:15, 
                  // backgroundColor:'#095FA9',
                  padding:5,
                  display:'flex',
                  flexDirection:'column',
                  alignItems:'center'
                }}>
                  <img src={Array.isArray(item.images)&&item.images[0].url} style={{width:180, margin:'auto'}} ></img>
                  <div style={{
                    width:'100%',
                  }}>
                    <p style={{width:'180px',margin:1, color:'black', fontSize:15, padding:2}}>  {item.address}</p>
                    <p style={{width:'180px',margin:1, color:'black', fontSize:15, padding:2}}>  {item.places} place(s) disponible(s)</p>
                    <p style={{width:'180px',margin:1, color:'black', fontSize:15, padding:2}}>{`(${createTableByItem(item.services, 'name').join(', ')})`}</p>
                    <p style={{width:'180px',margin:1, color:'black', fontSize:15, padding:2}}>{filterTable(props.user, 'userId', '6212bc6c6ca7c8e95f3e951b')&&filterTable(props.user, 'userId', '6212bc6c6ca7c8e95f3e951b')[0].email}</p>
                  </div>
                </div>
              </InfoWindow>}
              </Marker>
            ))
          }
        </MarkerClusterer>}
        
      </GoogleMap>
  )
}
const mapStateToProps = (state) => {
  return {notistack: state.notification.notistack, auth: state.auth, panel: state.panel, user: state.user};
};

export default connect(mapStateToProps)(MapWithMarkerClusterer)