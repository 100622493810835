import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import MoreComponent from "./MoreComponent";
import { connect } from "react-redux";
import { formatDate } from "../function/dateFunction";
import { getAllList } from "../Store/Requests/getRequest";
import { requestInterceptor } from "../interceptor/interceptor";
import { useEffect, useState } from "react";
import AlerteModal from "./AlerteModal";
import AlertePopUp from "./AlertePopUp";

function AlerteComponent(props) {
  const [init, setInit]= useState(false)
  const [popUpOpen, setpopUpOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [page, setPage] = useState(1);
  const [alerte,setAlerte] = useState({})
  
    useEffect(()=>{

      //Récupération de la liste des panneaux
      if(!props.panel.length){
        getAllList('panels', props.auth.access_token).then((data)=>{
          if(requestInterceptor(data, props.notistack)){
            const action = {type:'ADD_PANEL', value:data.data}
            props.dispatch(action)
            console.log('alerte component panel', data)
          }
        })
      }

      //Récupération de la liste des utilisateurs
      if(!props.user.length){
        getAllList('users', props.auth.access_token).then((data)=>{
          if(requestInterceptor(data, props.notistack)){
              const action = {type:'ADD_USER', value:data.data}
              props.dispatch(action)
              console.log('alerte component user', data)
          }
        })
      }
      // Récupération de la liste des différentes notifications (alerte non résolue)
      if(!props.alerte.length){
        getAllList('notification', props.auth.access_token).then((data)=>{
          console.log ('notification notification notification',data)
          if(requestInterceptor(data, props.notistack)){
              const action = {type:'ADD_ALERTE', value:data.data}
              props.dispatch(action)
              setInit(true)
          }
          })
        }
    }, [init])
  return (
    <div>
      <AlerteModal open={modalOpen} setOpen={setModalOpen} data={alerte} ></AlerteModal>
      <AlertePopUp open={popUpOpen} setOpen={setpopUpOpen} data={alerte} ></AlertePopUp>
      <Typography
        sx={{ marginLeft: 3, marginTop: 5 }}
        variant="h5"
        component="div"
        gutterBottom
      >
        Liste des alertes à résoudre
      </Typography>
      <TableContainer component={Paper} sx ={{width:"97%", margin:'0 auto', marginTop:3}}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>N°</TableCell>
              <TableCell>Date de création</TableCell>
              {/*<TableCell>Etat de l'alerte</TableCell>*/}
              <TableCell>Description</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              props.alerte.map(
                (item, index)=>
                <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component="th" scope="row">
                    {index+(page-1)*25+1}
                  </TableCell>
                  <TableCell>{formatDate(item.createdAt)}</TableCell>
                  {/*<TableCell>{item.isResolved?'Résolue':'Non résolue'}</TableCell>*/}
                  <TableCell>{item.description}</TableCell>

                  <TableCell><MoreComponent setAlerteOpen={props.setOpen} setAlerte={setAlerte} data={item} setpopUpOpen={setpopUpOpen} setModalOpen={setModalOpen} ></MoreComponent></TableCell>

                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {notistack: state.notification.notistack, auth: state.auth, alerte:state.alerte,panel:state.panel, user:state.user};
};

export default connect(mapStateToProps)(AlerteComponent)