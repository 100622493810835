const initialState=[]

const toggleAlerte =(state=initialState, action)=>{
    let nextState
    let index
    switch (action.type) {
        case 'ADD_ALERTE':
            nextState=[...action.value]
            break;
        case 'UPDATE_ALERTE':
            nextState = state
            index = state.findIndex((item) => item.id === action.value.id)
            if (index != -1) {
                nextState[index] = {
                ...nextState[index],
                ...action.value
                }
            } else {
                nextState = [...action.value, ...nextState]
            }
            break;
        case 'DELETE_ALERTE':
            nextState = state.filter((item)=>item.id!=action.value.id)
            break;
        default:
            return state
            break;
    }
    return nextState || state
}


export {toggleAlerte}