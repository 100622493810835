import { useEffect, useState } from "react"
import AlerteComponent from "./AlerteComponent"
import AlerteModal from "./AlerteModal"
import AlertePopUp from "./AlertePopUp"

export default function AlerteMainComponent(props){
    const [open, setOpen] = useState(false)
    const [alerte, setAlerte] = useState(null) 
    return <>
    <AlerteComponent setOpen={setOpen} setAlerte={setAlerte} alerte={alerte}></AlerteComponent>
    <AlerteModal open={open} setOpen={setOpen} alerte={alerte} setAlerte={setAlerte}></AlerteModal>
    </>
}