import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, Menu, MenuItem, Pagination } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreComponent from "./MoreComponent";
import { connect } from "react-redux";
import { formatDate } from "../function/dateFunction";
import { getAllList } from "../Store/Requests/getRequest";
import { requestInterceptor } from "../interceptor/interceptor";
import { useEffect, useState } from "react";
import { filterTable, pagination, searchInArray, sortTable } from "../function/arrayFunction";
import PanelPopUp from "../panel/PanelPopUp";
import PanelModal from "../panel/panelModal";

function ClientComponent(props) {
  const [init, setInit]= useState(false)
  const [popUpOpen, setpopUpOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
  };
    useEffect(()=>{
        if(!props.user.length){
          getAllList('users', props.auth.access_token).then((data)=>{
            if(requestInterceptor(data, props.notistack)){
                const action = {type:'ADD_USER', value:sortTable(filterTable(data.data, 'role', 'TECHNICIAN'),'createdAt', 'type',)}
                props.dispatch(action)
                setInit(true)
            }
          })
        }
        if(!props.panel.length){
          getAllList('panels', props.auth.access_token).then((data)=>{
            if(requestInterceptor(data, props.notistack)){
              const action = {type:'ADD_PANEL', value:data.data}
              props.dispatch(action)
            }
          })
          setInit(true)
        }
    }, [init])
    const [search, setSearch] = useState('')
    const [user, setUser] = useState([])
    useEffect(()=>{
      if(search){
        setUser(searchInArray(props.user, ['firstName', 'lastName', 'email'], search))
      }else{
        setUser(pagination(props.user, page, 25))
      }
    }, [search, props.user, page])

  return (
    <div>
      <PanelPopUp open={popUpOpen} setOpen={setpopUpOpen} client={props.client} ></PanelPopUp>
      <PanelModal open={modalOpen} setOpen={setModalOpen} client={props.client} ></PanelModal>
      <Typography
        sx={{ marginLeft: 3, marginTop: 5 }}
        variant="h5"
        component="div"
        gutterBottom
      >
        Liste des comptes clients
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} md={8}>
          <TextField
            onChange={(e)=>setSearch(e.target.value)}
            sx={{ marginLeft: 3, width: "50%" }}
            id="outlined-basic"
            label="rechercher un compte client"
            variant="outlined"
          />
        </Grid>
        <Grid sx={{ textAlign: "right" }} item xs={6} md={4}>
          <Button
            onClick={()=>props.setOpen(true)}
            variant="contained"
            sx={{ height: "70%", width: "60%", marginRight: 2 }}
          >
            Créer un compte
          </Button>
        </Grid>
      </Grid>
      {!search? <div style={{display:'flex', justifyContent:'center', padding:10}}>
     {props.user.length?<Pagination count={Math.ceil(props.user.length/25)} page={page} onChange={handleChange} />:<div></div>}
     </div>:<div></div>}
      <TableContainer component={Paper} sx ={{width:"97%", margin:'0 auto', marginTop:3}}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>N°</TableCell>
              <TableCell>NOM</TableCell>
              <TableCell>PRÉNOMS</TableCell>
              <TableCell>E-MAIL</TableCell>
              <TableCell>DATE</TableCell>
              <TableCell>NBR DE PANNEAUX</TableCell>
              <TableCell>ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              user.map((item, index)=>
                <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {index+(page-1)*25+1}
              </TableCell>
              <TableCell>{item.firstName}</TableCell>
              <TableCell>{item.lastName}</TableCell>
              <TableCell>{item.email}</TableCell>
              <TableCell>{formatDate(item.createdAt)}</TableCell>
              <TableCell>{filterTable(props.panel, 'clientId', item.userId).length}</TableCell>
              <TableCell><MoreComponent setClientOpen={props.setOpen} setClient={props.setClient} data={item} setpopUpOpen={setpopUpOpen} setModalOpen={setModalOpen} ></MoreComponent></TableCell>
            </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    {!search? <div style={{display:'flex', justifyContent:'center', padding:10}}>
     {props.user.length?<Pagination count={Math.ceil(props.user.length/25)} page={page} onChange={handleChange} />:<div></div>}
     </div>:<div></div>}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {notistack: state.notification.notistack, auth: state.auth, panel:state.panel, user:state.user};
};

export default connect(mapStateToProps)(ClientComponent)