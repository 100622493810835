import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import {UpdateUserValidator} from '../Validators/userValidator'
import {postRequest} from "../Store/Requests/postRequest";
import {connect} from "react-redux";
import {requestInterceptor} from "../interceptor/interceptor";
import {generateString} from "../function/stringFunction";
import { useValidation } from "react-class-validator";
import { putRequest } from "../Store/Requests/putRequest";

const steps = ["Création de compte", "Attribution de panneau"];

function UpdatePanel(props) {
const [userValidate, userErrors] = useValidation(UpdateUserValidator)
const [values, setValues] = React.useState({})
const handleUserInputChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }
React.useEffect(()=>{
    if(props.client){
        setValues({
            lastName:props.client.lastName,
            firstName:props.client.firstName
        })
    }
}, [props.client])
const submit=()=>{
    userValidate(values).then((data)=>{
        if(data&&props.client){
            putRequest(values, props.client.userId,'users').then((data)=>{
              console.log(props)
                if(requestInterceptor(data, props.notistack, "Le compte été modifé avec succès")){
                    props.setClient(null)
                    const action = {type:'UPDATE_USER', value:data.data}
                    props.dispatch(action)
                }
            })
        }
    })
}
  return (
    <Box sx={{
      width: "100%"
    }}>
      <div
        style
        ={{
        display: "flex",
        flexDirection: "column",
        marginTop: 20
      }}>
        <TextField
          name='Nom du panneau'
          value={values.name}
          onChange={handleUserInputChange}
          sx={{
          marginBottom: 0,
          backgroundColor: "#F9FDFF"
        }}
          id="outlined-basic"
          label="Entrez le nom du panneau"
          variant="outlined"/>
        <Typography
          sx={{
          color: 'red',
          textAlign: 'center',
          mb: 2,
          fontSize: 12
        }}>
          {userErrors.firstName && userErrors.firstName[0]}
        </Typography>
        <TextField
          name='lastName'
          value={values.lastName}
          onChange={handleUserInputChange}
          sx={{
          backgroundColor: "#F9FDFF"
        }}
          id="outlined-basic"
          label="Entrez votre prénoms"
          variant="outlined"/>
        <Typography
          sx={{
          color: 'red',
          textAlign: 'center',
          mb: 2,
          fontSize: 12
        }}>
          {userErrors.lastName && userErrors.lastName[0]}
        </Typography>
       
      </div>
      <Box
          sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2
        }}>
          <Button
            variant="outlined"
            color="inherit"
            onClick={()=>{props.setOpen(false); props.setClient(null)}}
            sx={{
            mr: 1
          }}>
            Annuler
          </Button>
          <Box sx={{
            flex: "1 1 auto"
          }}/>
          <Button variant="contained" onClick={submit} >
            Enregistrer
          </Button>
        </Box>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {notistack: state.notification.notistack, auth: state.auth, panel: state.panel};
};

export default connect(mapStateToProps)(UpdatePanel)