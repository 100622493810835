const initialState={}

const toggleNotification =(state=initialState, action)=>{
    let nextState
    switch (action.type) {
        case 'CREATE_NOTIFICATION_POP_UP':
            nextState={...state, createNotificationPopUp:action.value}
            break;
        case 'NOTISTACK':
            nextState ={...state,  notistack:action.value}
            break;
       
        default:
            return state
            break;
    }
    return nextState || state
}


export {toggleNotification}