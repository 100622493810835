import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  makeStyles,
  OutlinedInput,
  TextField,
  Typography
} from "@mui/material"
import LoginImage from '../../assets/Dashboard Ilena-min.png'
import Logo from '../../assets/logo.png'
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import {useState} from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Input from '@mui/material/Input';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import {connect} from "react-redux";
import {postRequest} from "../../Store/Requests/postRequest";
import {requestInterceptor} from "../../interceptor/interceptor";
function Login(props) {

  const [passwordVisible,
    setPasswordVisible] = useState(false)
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [values,
    setValues] = useState({})
  const handleInputChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }
  const [errorMessage,
    setErrorMessage] = useState('')
  const submit = () => {
    postRequest(values, 'auth/login').then((data) => {
      if (requestInterceptor(data, props.notistack, '', [401])) {
        if(data.data.role=='ADMIN'){
          const action = {type:'LOGIN_USER', value:data.data}
          props.dispatch(action)
          sessionStorage.setItem('auth', JSON.stringify(data.data))
        }else{
          setErrorMessage("Vous n'avez pas accès à cette page")
        }
      }
      else if (data.statusCode == 401) {
        setErrorMessage(data.data.message)
      }
    })
  }
  return <div style={styles.container}>
    <div style={styles.section1}>
      <img src={LoginImage} style={styles.image}></img>
    </div>
    <div style={styles.section2}>
      <img src={Logo} style={styles.logo}></img>
      <div
        style={{
        marginTop: 20,
        display: 'flex',
        flexDirection: 'column',
        width: '60%'
      }}>
        <FormControl
          sx={{
          width: '100%',
          my: 3
        }}
          variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Email</InputLabel>
          <OutlinedInput
            id="outlined-adornment-email"
            name="username"
            onChange={handleInputChange}
            sx={{
            backgroundColor: '#F9FDFF'
          }}
            type={'text'}
            startAdornment={<AccountCircleIcon sx = {{color:'rgba(0,0,0,0.5)'}}> </AccountCircleIcon>}
            label="Email"/>
        </FormControl>
        <FormControl
          sx={{
          width: '100%',
          my: 1
        }}
          variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            name='password'
            onChange={handleInputChange}
            id="outlined-adornment-password"
            type={passwordVisible
            ? 'text'
            : 'password'}
            sx={{
            backgroundColor: '#F9FDFF'
          }}
            startAdornment={<LockIcon sx = {{color:'rgba(0,0,0,0.5)'}}> </LockIcon>}
            endAdornment={<InputAdornment position = "end"> <IconButton
            aria-label="toggle password visibility"
            onClick={() => setPasswordVisible(!passwordVisible)}
            onMouseDown={handleMouseDownPassword}
            edge="end">
            {passwordVisible
              ? <VisibilityOff/>
              : <Visibility/>}
          </IconButton> </InputAdornment>}
            label="Password"/>
        </FormControl>
        {/* <Link
          href="#"
          color="inherit"
          sx={{
          textAlign: 'end',
          textDecoration: 'none',
          my: 1
        }}>
          mot de passe oublié ?
        </Link> */}
        <Typography
          sx={{color:'red', textAlign:'center', my:2}}
        >
          {errorMessage}
        </Typography>
        <Button
          sx={{
          height: 50,
          width: '100%'
        }}
          onClick={submit}
          variant="contained">S'identifier</Button>
      </div>
    </div>
  </div>
}

const mapStateToProps = (state) => {
  console.log(state)
  return {notistack: state.notification.notistack, user: state.user};
};

export default connect(mapStateToProps)(Login)

const styles = {
  container: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    display: 'flex'
  },
  section1: {
    flex: 1,
    backgroundColor: '#1C9CD947',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  section2: {
    flex: 1,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    width: '60%',
    height: '60%'
  },
  logo: {
    height: 50
  }
}