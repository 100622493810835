import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CreateClientComponent from './createClientComponent';
import UpdateClientComponent from './updateClientComponent';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props}/>;
});

export default function ClientModal(props) {
  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description">
        <div style ={{padding:60}}>
          {
            props.client?<UpdateClientComponent setClient={props.setClient} client={props.client} setOpen={props.setOpen} ></UpdateClientComponent>:<CreateClientComponent  setOpen={props.setOpen} ></CreateClientComponent>
          }
        </div>
      </Dialog>
    </div>
  );
}