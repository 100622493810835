import * as React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {useValidation} from "react-class-validator";
import {UserValidator} from '../Validators/userValidator';
import {connect} from "react-redux";
import { useParams } from "react-router-dom";
import { formatDate } from "../function/dateFunction";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { filterTable } from "../function/arrayFunction";

function ListePanneauByID(props) {
  const {id} = useParams();
  const [panel, setPanel] = useState([]);
  
  useEffect(()=> {
    if(props.panel && props.data){
      const panel = filterTable(props.panel,'panelId',props.data)
      setPanel(panel)
    }
  },[props.data,props.user])

  return (<div style={{width:'auto', minHeight:'auto'}} >
  <Grid container spacing={2}>
    <Grid item xs={8}>
    <Typography
    sx={{ marginLeft: 3, marginTop: 5 }}
    variant="h5"
    component="div"
    gutterBottom
  >
    Panneau concerné
  </Typography>
    </Grid>
    <Grid item xs={1}>
    <IconButton sx={{marginLeft:14, marginTop:'auto'}} onClick={()=>props.setOpen(false)} >
      <CancelIcon sx={{color:'red'}} ></CancelIcon>
    </IconButton>
    </Grid>
  </Grid>
  <TableContainer component={Paper} sx ={{width:"auto", margin:'0 auto', marginTop:3}}>
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>N°</TableCell>
          <TableCell>ADRESSE</TableCell>
         {/* <TableCell>STATUS</TableCell>*/}
          <TableCell>TYPE DE PLACE</TableCell>
          <TableCell>TYPE DE STATIONNEMENT</TableCell>
         {/* <TableCell>DATE DE CREATION</TableCell>*/}
          {/* <TableCell>ACTION</TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {
          panel.map((item, index)=>
            <TableRow
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            {index+1}
          </TableCell>
          <TableCell>{item.address?item.address:'Non localisé'}</TableCell>
         {/* <TableCell>{item.isInstalled?'Installé':'Non installé'}</TableCell>*/}
          <TableCell>{item.placeType=='PUBLIC'?'publique':'privée'}</TableCell>
          <TableCell>{item.parkingType}</TableCell>
         {/* <TableCell>{formatDate(item.createdAt)}</TableCell>*/}
          {/* <TableCell><MoreComponent setClient={props.setClient} data={item} ></MoreComponent></TableCell> */}
        </TableRow>
          )
        }
      </TableBody>
    </Table>
  </TableContainer>
</div>
  );
}

const mapStateToProps = (state) => {
  return {notistack: state.notification.notistack, auth: state.auth, alerte:state.alerte, panel:state.panel, user:state.user};
};

export default connect(mapStateToProps)(ListePanneauByID)