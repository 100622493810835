import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { connect } from 'react-redux';
import { deleteRequest } from '../Store/Requests/deleteRequest';
import { requestInterceptor } from '../interceptor/interceptor';
function MoreComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteAlerte= ()=>{
    deleteRequest(props.data.id, 'notification', props.access_token).then((data)=>{
      if(requestInterceptor(data, props.notistack,"L'alerte a bien été supprimée avec succès")){
        const action= {type:'DELETE_ALERTE', value:{id:props.data.id}}
        props.dispatch(action)
      }
    })
  }

  return (
    <div>
       <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={()=>{handleClose(); props.setAlerte(props.data.panelId); props.setModalOpen(true)}}>Panneau concerné</MenuItem>
        <MenuItem onClick={()=>{handleClose(); props.setAlerte(props.data.userId); props.setpopUpOpen(true)}}>Utilisateur déclarant</MenuItem>
        <MenuItem onClick={()=>{handleClose(); props.createNotificationPopUp('Supprimer l\'alerte', `Voulez-vous supprimer l\'alerte ${props.data.description}`, 'OUI', deleteAlerte)}}>Supprimer l'alerte</MenuItem>
      </Menu>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {createNotificationPopUp: state.notification.createNotificationPopUp, auth:state.auth, notistack: state.notification.notistack};
};

export default connect(mapStateToProps)(MoreComponent)