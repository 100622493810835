import { useState } from 'react'
import MenuAppBar from '../appBar/appBar'
import Drawer from '../silder/silder'
export default function MainComponent(props) {
  return <div style={{
    display: 'flex',
    height:'100vh',
   position:'fixed',
   width:'100vw',
   overflowY:'auto',
   overflowX:'hidden',
   backgroundColor:'#F1F5F6',
  }}>
    <div style={{width:props.drawerVisible?'280px':'0px', transitionDuration:'0.3s'}}>
      {/* <Drawer></Drawer> */}
    </div>
    <div style={styles.menuBar}>
      <div style={{position:'sticky', top:0, zIndex:100}}><MenuAppBar setDrawerVIsible={props.setDrawerVIsible} drawerVisible={props.drawerVisible} ></MenuAppBar></div>
      <div style={{
        width: '100%',
      }}>
          {props.children}
      </div>
    </div>
  </div>
}

const styles = {
  menuBar: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    heigth: '100%',
    width:'100%'
  }
}