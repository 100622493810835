const initialState={}

const toggleAuth =(state=initialState, action)=>{
    let nextState
    switch (action.type) {
        case 'LOGIN_USER':
            nextState=action.value
            break;
        case 'LOGOUT_USER':
            nextState={}
            break;
        default:
            return state
            break;
    }
    return nextState || state
}


export {toggleAuth}