import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import ListePanneauByID from './ListePanneauByID';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props}/>;
});

export default function AlerteModal(props) {
  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description">
        <div style ={{padding:60}}>
          <ListePanneauByID setOpen={props.setOpen} data={props.data}></ListePanneauByID>
        </div>
      </Dialog>
    </div>
  );
}