import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';
import {useValidation} from "react-class-validator";
import {UserValidator} from '../Validators/userValidator'
import {postRequest} from "../Store/Requests/postRequest";
import {connect} from "react-redux";
import {requestInterceptor} from "../interceptor/interceptor";
import { generateString } from "../function/stringFunction";

const steps = ["Création de compte", "Attribution de panneau"];

function HorizontalLinearStepper(props) {
  const lengthOfPassword =10
  const [activeStep,
    setActiveStep] = React.useState(0);
  const [userValide,
    userErrors] = useValidation(UserValidator)
  const [skipped,
    setSkipped] = React.useState(new Set());
  const [userValues,
    setUserValues] = React.useState({})
  const [panelValues,
    setPanelValues] = React.useState({})
  const [panelErrors,
    setPanelErrors] = React.useState('')
  const isStepOptional = step => {
    return step === 1;
  };
  const submit = () => {
    postRequest({
      ...userValues,
      "password": generateString(lengthOfPassword),
      "role": "TECHNICIAN",
      "source": "MOBILE_USER"
    }, 'users', props.auth.access_token).then((data) => {
      if (requestInterceptor(data, props.notistack, "L'utilisateur a été créé avec succès")) {
        const action = {
          type: 'ADD_USER_ONE',
          value: data.data
        }
        props.dispatch(action)
        const userId = data.data.userId
        for (let i = 0; i < panelValues.number; i++) {
          const panelData = {
            "name": `Mon panneau test ${i+1}`,
            "clientId": userId,
            "isInstalled": false,
            "orderId": "AZERTYUIOP1234",
            "services": [],
            "places": 0,
            "location": {},
            "description": "",
            "parkingType": "véhicule",
            "address": ""
          }
          postRequest(panelData, 'panels', props.auth.access_token).then((data)=>{
              if(requestInterceptor(data.data,props.notistack, (i==panelValues.number-1?'Les panneaux ont été crée avec succès':undefined))){
                const action = {type:'ADD_PANEL_ONE', value:data.data}
                props.dispatch(action)
                props.setOpen(false)
                setUserValues({})
                setPanelValues({})
              }
          })
        }
      }
    })
  }
  const isStepSkipped = step => {
    return skipped.has(step);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (activeStep == 0) {
      userValide(userValues).then((data) => {
        if (data) {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          setSkipped(newSkipped);
        }
      })
    } else if (activeStep == 1) {
      if (panelValues.number &&+ panelValues.number && panelValues.number > 0) {
        setPanelErrors('')
        submit()
      } else {
        setPanelErrors('Le nombre de panneaux doit être supérieur à 0')
      }
    }
  };
  const handleUserInputChange = e => {
    setUserValues({
      ...userValues,
      [e.target.name]: e.target.value
    })
  }
  const handlePanelInputChange = e => {
    setPanelValues({
      ...panelValues,
      [e.target.name]: e.target.value
    })
  }
  const handleBack = () => {
    if (activeStep == 0) {
      props.setOpen(false)
      setUserValues({})
      setPanelValues({})
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
    }
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {

      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setSkipped(prevSkipped => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{
      width: "100%"
    }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <React.Fragment>
        {activeStep == 0
          ? (
            <div
              style
              ={{
              display: "flex",
              flexDirection: "column",
              marginTop: 20
            }}>
              <TextField
                name='firstName'
                value={userValues.firstName}
                onChange={handleUserInputChange}
                sx={{
                marginBottom: 0,
                backgroundColor: "#F9FDFF"
              }}
                id="outlined-basic"
                label="Entrez votre nom"
                variant="outlined"/>
              <Typography
                sx={{
                color: 'red',
                textAlign: 'center',
                mb: 2,
                fontSize: 12
              }}>
                {userErrors.firstName && userErrors.firstName[0]}
              </Typography>
              <TextField
                name='lastName'
                value={userValues.lastName}
                onChange={handleUserInputChange}
                sx={{
                backgroundColor: "#F9FDFF"
              }}
                id="outlined-basic"
                label="Entrez votre prénoms"
                variant="outlined"/>
              <Typography
                sx={{
                color: 'red',
                textAlign: 'center',
                mb: 2,
                fontSize: 12
              }}>
                {userErrors.lastName && userErrors.lastName[0]}
              </Typography>
              <TextField
                onChange={handleUserInputChange}
                name='email'
                value={userValues.email}
                sx={{
                backgroundColor: "#F9FDFF"
              }}
                id="outlined-basic"
                label="Entrez votre adresse e-mail"
                variant="outlined"/>
              <Typography
                sx={{
                color: 'red',
                textAlign: 'center',
                mb: 2,
                fontSize: 12
              }}>
                {userErrors.email && userErrors.email[0]}
              </Typography>
            </div>
          )
          : (
            <div
              style
              ={{
              display: "flex",
              flexDirection: "column",
              marginTop: 20
            }}>
              <TextField
                sx={{
                marginTop: 5,
                backgroundColor: "#F9FDFF"
              }}
                id="outlined-basic"
                type='number'
                name='number'
                value={panelValues.number}
                onChange={handlePanelInputChange}
                label="Entrez le nombre de panneau "
                variant="outlined"/>
              <Typography
                sx={{
                color: 'red',
                textAlign: 'center',
                mb: 2,
                fontSize: 12
              }}>
                {panelErrors}
              </Typography>
            </div>
          )}

        <Box
          sx={{
          display: "flex",
          flexDirection: "row",
          pt: 2
        }}>
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleBack}
            sx={{
            mr: 1
          }}>
            {activeStep == 0
              ? 'Annuler'
              : 'Retour'
}

          </Button>
          <Box sx={{
            flex: "1 1 auto"
          }}/>
          <Button variant="contained" onClick={handleNext}>
            {activeStep === steps.length - 1
              ? "Enregistrer"
              : "Suivant"}
          </Button>
        </Box>
      </React.Fragment>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {notistack: state.notification.notistack, auth: state.auth, panel:state.panel};
};

export default connect(mapStateToProps)(HorizontalLinearStepper)