import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { postRequest } from '../Store/Requests/postRequest';
import { deleteRequest } from '../Store/Requests/deleteRequest';
import { requestInterceptor } from '../interceptor/interceptor';
function MoreComponent(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteArticle= ()=>{
    deleteRequest(props.data.id, 'wp-posts', props.access_token).then((data)=>{
      if(requestInterceptor(data, props.notistack,"L'article a été supprimé avec succès" +" - "+data.title +"- "+data.id)){
        const action= {type:'DELETE_ARTICLE', value:{id:props.data.id}}
        props.dispatch(action)
      }
    })
  }

  return (
    <div>
       <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={()=>{handleClose(); props.createNotificationPopUp('Suppression de l\'article ', `Voulez-vous supprimer l'article ${props.data.title}`, 'OUI', deleteArticle)}}>Supprimer Article</MenuItem>
      </Menu>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {createNotificationPopUp: state.notification.createNotificationPopUp, auth:state.auth, notistack: state.notification.notistack};
};

export default connect(mapStateToProps)(MoreComponent)