import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {connect} from 'react-redux';

function MenuAppBar(props) {
  const [anchorEl,
    setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{
      flexGrow: 1
    }}>
      <AppBar position="static" sx={{
        backgroundColor: 'white'
      }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            onClick={() => props.setDrawerVIsible(!props.drawerVisible)}
            aria-label="menu"
            sx={{
            mr: 2
          }}>
            <MenuIcon/>
          </IconButton>
          <div style={styles.loginContener}>

            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              sx={{
              borderRadius: 0
            }}
              onClick={handleMenu}>
              <AccountCircle/>
              <Typography style={{
                marginLeft: 5
              }}>Bonjour, {props.auth.firstName}</Typography>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
              keepMounted
              transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
              open={Boolean(anchorEl)}
              onClose={handleClose}>
              <MenuItem
                onClick={() => {
                handleClose();
                props.dispatch({type: 'LOGOUT_USER'});
                console.log(props.auth)
                sessionStorage.setItem('auth', null)
              }}>Déconnexion
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {auth: state.auth};
};

export default connect(mapStateToProps)(MenuAppBar)

const styles = {
  loginContener: {
    marginLeft: 'auto'
  }
}