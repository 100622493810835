import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import {SnackbarProvider, useSnackbar} from 'notistack';
import {connect} from 'react-redux';

function MyApp(props) {
  const {enqueueSnackbar} = useSnackbar();
  const handleClick = (message, option) => {
    enqueueSnackbar(message, option);
  };
  const [init,
    setInit] = useState()

  useEffect(() => {
    props.dispatch({type: 'NOTISTACK', value: handleClick})
  }, [init])
  return (
    <React.Fragment></React.Fragment>
  );
}

function IntegrationNotistack(props) {
  return (
    <SnackbarProvider
      anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
      maxSnack={3}>
      <MyApp {...props}/>
    </SnackbarProvider>
  );
}

const mapStateToProps = (state) => {
  console.log(state.notification.notistack)
  return {notistack: state.notification.notistack};
};

export default connect(mapStateToProps)(IntegrationNotistack);
