import { objectIsEmpty } from "../../function/objectFunction";
import server from "../../server";

const getAllList = async(uri, token, query) => {
  if(!objectIsEmpty(query)){
    uri =uri+'?'
    for(let x in query){
      uri = uri +x+'='+query[x]
    }
  }
  
  const getPromise = () => new Promise((resolve, reject) => {
    fetch(server + uri, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then(async(res) => {
      return {
        statusCode: res.status,
        data : await res.json()
      }
    }).then((data) => {
      resolve(data);
    }).catch((error)=>{
      resolve({
        statusCode: 1000,
        data : undefined
      })
    });
  });
  let result = await getPromise();
  console.log(result)
  return result;
};


const getById = async(id, uri, token, query)=>{
  uri=uri+'/'+id
  if(query&&!objectIsEmpty(query)){
    uri =uri+'?'
    for(let x in query){
      uri = uri +x+'='+query[x]
    }
  }
  const getPromise = () => new Promise((resolve, reject) => {
    fetch(server + uri, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then(async(res) => {
      return {
        statusCode: res.status,
        data : await res.json()
      }
    }).then((data) => {
      resolve(data);
    });
  });
  let result = await getPromise();
  return result;
}
export {getAllList,getById}