import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Avatar, IconButton, Menu, MenuItem, Pagination } from "@mui/material";
import MoreComponent from "./MoreComponent";
import { connect } from "react-redux";
import { getAllList } from "../Store/Requests/getRequest";
import { requestInterceptor } from "../interceptor/interceptor";
import { useEffect, useState } from "react";
import ArticleModal from "./ArticleModal";


function ArticleComponent(props) {
  const [init, setInit]= useState(false)
  const [popUpOpen, setpopUpOpen] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [page, setPage] = useState(1);
  const [article,setArticle] = useState([])
  const [search, setSearch] = useState('')
  const handleChange = (event, value) => {
    setPage(value);
  };
    useEffect(()=>{
      if(!props.article.length){
          getAllList('wp-posts/v2', props.auth.access_token).then((data)=>{
            if(requestInterceptor(data, props.notistack)){
                const action = {type:'ADD_ARTICLE', value:data.data}
                props.dispatch(action)
                setInit(true)
            }
          })
        }
    }, [init])
  return (
    <div>
      <ArticleModal open={modalOpen} setOpen={setModalOpen} article={props.article} ></ArticleModal>
      <Typography
        sx={{ marginLeft: 3, marginTop: 5 }}
        variant="h5"
        component="div"
        gutterBottom
      >
        Liste des articles
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6} md={8}>
          
        </Grid>
        <Grid sx={{ textAlign: "right" }} item xs={6} md={4}>
          <Button
            onClick={()=>props.setOpen(true)}
            variant="contained"
            sx={{ height: "70%", width: "60%", marginRight: 2 }}
          >
            Créer un article
          </Button>
        </Grid>
      </Grid>
   
      <TableContainer component={Paper} sx ={{width:"97%", margin:'0 auto', marginTop:3}}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>N°</TableCell>
              {<TableCell>Source</TableCell>}
              <TableCell>Texte</TableCell>
              <TableCell>Titre</TableCell>
              <TableCell>Action</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {
              props.article.map((item, index)=>
                <TableRow
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {index+(page-1)*25+1}
              </TableCell>
              {<TableCell> <Avatar alt="Remy Sharp" src={item.source_url} /></TableCell>}
              <TableCell>{item.text}</TableCell>
              <TableCell>{item.title}</TableCell>
              <TableCell><MoreComponent setArticleOpen={props.setOpen} setArticle={props.setClient} data={item} setpopUpOpen={setpopUpOpen} setModalOpen={setModalOpen} ></MoreComponent></TableCell>
             </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {notistack: state.notification.notistack, auth: state.auth, article:state.article};
};

export default connect(mapStateToProps)(ArticleComponent)