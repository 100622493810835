import { useEffect, useState } from "react"
import ClientComponent from "./ClientComponent"
import ClientModal from "./ClientModal"

export default function ClientMainComponent(props){
    const [open, setOpen] = useState(false)
    const [client, setClient] = useState(null) 
    return <>
    <ClientComponent setOpen={setOpen} setClient={setClient} client={client}   ></ClientComponent>
    <ClientModal open={open} setOpen={setOpen} client={client} setClient={setClient} ></ClientModal>
    </>
}