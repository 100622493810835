import { useEffect, useState } from "react"
import ArticleComponent from "./ArticleComponent"
import ArticleModale from "./ArticleModal"

export default function ArticleMainComponent(props){
    const [open, setOpen] = useState(false)
    const [article, setArticle] = useState(null) 
    return <>
    <ArticleComponent setOpen={setOpen} setArticle={setArticle} article={article}></ArticleComponent>
    <ArticleModale open={open} setOpen={setOpen} article={article} setArticle={setArticle}></ArticleModale>
    </>
}