import server from "../../server";

const deleteRequest = async(id,uri, token)=>{
    const getPromise = () => new Promise((resolve, reject) => {
      fetch(server + uri+'/'+id, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      }).then(async(res) => {
        let data= {}
        try{
          let data = await res.json()
        }
        catch(e){
         data ={}
        }
        return {
          statusCode: res.status,
          data : data
        }
      }).then((data) => {
        resolve(data);
      });
    });
    let result = await getPromise();
    return result;
  }

export {deleteRequest}