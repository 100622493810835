import logo from './logo.svg';
import './App.css';
import Drawer from './silder/silder';
import AppBar from './appBar/appBar';
import MainComponent from './MainComponent/MainComponent';
import Artic from './ArticleComponent/ArticleComponent';
import {Routes, Route, BrowserRouter} from "react-router-dom";
import DrawerView from './silder/silder';
import Dashboard from './dashBord.js/dashBord';
import {useEffect, useState} from 'react';
import Snackbar from './notification/Snackbar';
import Store from './Store/configStore'
import {Provider} from 'react-redux';
import {Storefront} from '@mui/icons-material';
import Login from './Auth/Login/login';
import ClientComponent from './ClientComponent/ClientComponent'
import ClientMainComponent from './ClientComponent/ClientMainComponent';
import ArticleMainComponent from './ArticleComponent/ArticleMainComponent';
import AlerteMainComponent from './AlerteComponent/AlerteMainComponent';
import {postRequest} from './Store/Requests/postRequest'
import { ValidatorProvider } from 'react-class-validator';
import { _getDefaultContextOptions } from './Validators/validationOption'
import PanelMain from './panel/panelMain';
import PopUpNotification from './notification/popUpNotification';
import { LoadScript } from '@react-google-maps/api';
function App() {
  const [drawerVisible,
    setDrawerVIsible] = useState(true)
  const [isAuth,
    setIsAuth] = useState(false)
  const [init,
    setInit] = useState(false)
  useEffect(() => {
    const user = JSON.parse(sessionStorage.getItem("auth", Store.getState().auth));
    console.log(sessionStorage.getItem("auth", Store.getState().auth))
    if (user) {
      Store.dispatch({type: "LOGIN_USER", value: user});
    }
    Store.subscribe(() => setIsAuth(Store.getState().auth&&Store.getState().auth.access_token || false));
  }, [init])
  return (
    <LoadScript
    scriptjs={false}
    googleMapsApiKey='AIzaSyDThe3vHgiW9cxqJqsdDYZbmRUyyUF3Ufk'
    >
    <ValidatorProvider options={_getDefaultContextOptions} >
    <Provider store={Store}>
       <Snackbar></Snackbar>
       <PopUpNotification></PopUpNotification>
      {!isAuth
        ? <Login></Login>
        : <MainComponent
          setDrawerVIsible={setDrawerVIsible}
          drawerVisible={drawerVisible}>
          <BrowserRouter>
            <Routes>
              <Route path='/' element={< Dashboard > </Dashboard>}/>
              <Route path='client' element={< ClientMainComponent > </ClientMainComponent>}></Route>
              <Route path='article' element={< ArticleMainComponent > </ArticleMainComponent>}></Route>
              <Route path='alerte' element={< AlerteMainComponent > </AlerteMainComponent>}></Route>
              <Route path='panneau'>
                <Route index element={<PanelMain></PanelMain>} ></Route>7
                <Route path=':id' element={<PanelMain></PanelMain>}></Route>
              </Route>
            </Routes>
            <DrawerView visible={drawerVisible}></DrawerView>
          </BrowserRouter>
        </MainComponent>
}
    </Provider>
    </ValidatorProvider>
    </LoadScript>
  );
}

export default App;
