import * as React from "react";
import Typography from "@mui/material/Typography";
import {connect} from "react-redux";
import { formatDate } from "../function/dateFunction";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton, Menu, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { filterTable } from "../function/arrayFunction";

function ListePanneauByID(props) {
  const [user, setUser] = React.useState([])
  
  useEffect(()=> {
    if(props.user && props.data){
      const user = filterTable(props.user,'userId',props.data)
      setUser(user)
    }
  },[props.user,props.data])

  return (<div style={{width:'auto', minHeight:'auto'}} >
  <Grid container spacing={2}>
    <Grid item xs={8}>
    <Typography
    sx={{ marginLeft: 3, marginTop: 5 }}
    variant="h5"
    component="div"
    gutterBottom
  >
    Déclarant de l'alerte
  </Typography>
  </Grid>
    <Grid item xs={1}>
    <IconButton sx={{marginLeft:14, marginTop:'auto'}} onClick={()=>props.setOpen(false)} >
      <CancelIcon sx={{color:'red'}} ></CancelIcon>
    </IconButton>
    </Grid>
  </Grid>
 
  <TableContainer component={Paper} sx ={{width:"auto", margin:'0 auto', marginTop:3}}>
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>N°</TableCell>
          <TableCell>NOM</TableCell>
          <TableCell>PRENOM(S)</TableCell>
          <TableCell>EMAIL</TableCell>
          {/*<TableCell>DATE CREATION</TableCell>*/}
        </TableRow>
      </TableHead>
      <TableBody>
        {
          user.map((item, index)=>
            <TableRow
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        >
          <TableCell component="th" scope="row">
            {index+1}
          </TableCell>
          <TableCell>{item.username}</TableCell>
          <TableCell>{item.lastName}</TableCell>
          <TableCell>{item.email}</TableCell>
          {/*<TableCell>{formatDate(item.createdAt)}</TableCell>*/}
          {/* <TableCell><MoreComponent setClient={props.setClient} data={item} ></MoreComponent></TableCell> */}
        </TableRow>
          )
        }
      </TableBody>
    </Table>
  </TableContainer>
</div>
  );
}

const mapStateToProps = (state) => {
  return {notistack: state.notification.notistack, auth: state.auth, alerte:state.alerte, panel:state.panel,user:state.user};
};

export default connect(mapStateToProps)(ListePanneauByID)