import {Grid, Typography} from "@mui/material"
import AccountBoxIcon from '@mui/icons-material/AccountBox';
// import MapComponent, { MapView } from "./map/map";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {getAllList}  from '../Store/Requests/getRequest'
import {requestInterceptor}  from '../interceptor/interceptor'
import { filterTable, sortTable } from "../function/arrayFunction";
import GoogleMapContainer from "./map/map2";
// import Map from "./map/google/Map";
function Dashboard(props) {
  const action = {type:'', value:{}}
  props.dispatch(action)

  const [init, setInit] = useState(false)

  useEffect(()=>{
    if(!init){
      getAllList('users', props.auth.access_token).then((data)=>{
        if(requestInterceptor(data, props.notistack)){
            const action = {type:'ADD_USER', value:sortTable(filterTable(data.data, 'role', 'TECHNICIAN'),'createdAt', 'type',)}
            props.dispatch(action)
        }
      })
      getAllList('panels', props.auth.access_token).then((data)=>{
        if(requestInterceptor(data, props.notistack)){
          const action = {type:'ADD_PANEL', value:data.data}
          props.dispatch(action)
          console.log('dashbord.js', data)
        }
      })
      setInit(true)
    }
  }, [init])
  return <div>
    <div
      style={{
      width: '80%',
      height: 70,
      margin: 'auto',
      marginTop: 20,
      display: 'flex',
      justifyContent: 'center'
    }}>
      <div style={styles.card}>
        <div style={{
          flex: 2,
          height: '100%'
        }}>
          <div>
            <Typography sx={{
              fontSize: 18
            }}>Installateurs</Typography>
            <Typography
              sx={{
              fontSize: 30,
              color: '#095FA9',
              fontWeight: 600
            }}>{props.user.length}</Typography>
          </div>
        </div>
        <div
          style={{
          flex: 1.8,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <AccountBoxIcon
            sx={{
            fontSize: 50,
            color: ''
          }}></AccountBoxIcon>
        </div>
      </div>
      <div style={styles.card}>
        <div style={{
          flex: 2,
          height: '100%'
        }}>
          <div>
            <Typography sx={{
              fontSize: 18
            }}>Panneaux installés</Typography>
            <Typography
              sx={{
              fontSize: 30,
              color: '#095FA9',
              fontWeight: 600
            }}>{filterTable(props.panel, 'isInstalled', true).length}</Typography>
          </div>
        </div>
        <div
          style={{
          flex: 1,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
           <img src= {require('../assets/panneau routier.png')} style={{width:45, height:45}} ></img>
          {/* <AccountBoxIcon
            sx={{
            fontSize: 50,
            color: ''
          }}></AccountBoxIcon> */}
        </div>
      </div>
      <div style={styles.card}>
        <div style={{
          flex: 3,
          height: '100%'
        }}>
          <div>
            <Typography sx={{
              fontSize: 18
            }}>Panneaux non installés</Typography>
            <Typography
              sx={{
              fontSize: 30,
              color: '#095FA9',
              fontWeight: 600
            }}>{filterTable(props.panel, 'isInstalled', false).length}</Typography>
          </div>
        </div>
        <div
          style={{
          flex: 1,
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          <img src= {require('../assets/Passage  pieton.png')} style={{width:45, height:45}} ></img>
          {/* <AccountBoxIcon
            sx={{
            fontSize: 50,
            color: ''
          }}></AccountBoxIcon> */}
        </div>
      </div>

    </div>
    <div style={{
      marginTop: 50,
      paddingLeft: 20
    }}>
      <Typography sx={{
        fontSize: 18, textAlign:'center', my:2
      }}>Position des panneaux</Typography>
      <div style={{width:'100%',display:'flex', justifyContent:'center'}}>
        <GoogleMapContainer></GoogleMapContainer>
        {/* <GoogleMapContainer
        defaultOptions={{ scaleControl: true }}
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyBt5F_65miIiCpSgOEmbg3hACbgU0RwZas&libraries=visualization,drawing,geometry,places`}
        loadingElement={<div style={{ height: '100%' }}> Loading... </div>}
        containerElement={<div style={{ height: '100vh' }} />}
        mapElement={<div style={{ height: '100%' }} />}
        ></GoogleMapContainer> */}
      </div>
    </div>
  </div>
}

const styles = {
  card: {
    height: '100%',
    width: 340,
    borderRadius: 5,
    display: 'flex',
    padding: 10,
    backgroundColor: 'white',
    boxShadow: '0px 0px 1px black',
    marginLeft: 20
  }
}
const mapStateToProps = (state) => {
    return {notistack: state.notification.notistack,auth:state.auth, user: state.user, panel:state.panel};
  };

export default connect(mapStateToProps)(Dashboard)



