import {IsEmail, IsNotEmpty}  from 'class-validator'
class UserValidator{
    @IsNotEmpty({
        message:'Le champ nom ne doit pas être vide'
    })
    firstName:string

    @IsNotEmpty({
        message:'Le champ prénom ne doit pas être vide'
    })
    lastName:string

    @IsEmail({
        
    }, {message:'Le format de l\'email n\'est pas correcte'})
    email:string
}
class UpdateUserValidator{
    @IsNotEmpty({
        message:'Le champ nom ne doit pas être vide'
    })
    firstName:string

    @IsNotEmpty({
        message:'Le champ prénom ne doit pas être vide'
    })
    lastName:string

}

export {UserValidator, UpdateUserValidator}