import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import UpdatePanel from './updatePanel';
import CreatePanel from './createPanel';
import PanelList from './panelList';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props}/>;
});

export default function PanelPopUp(props) {
  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth='lg'
        aria-describedby="alert-dialog-slide-description">
        <div style ={{padding:5}}>
          <PanelList setOpen={props.setOpen} client={props.client} ></PanelList>
        </div>
      </Dialog>
    </div>
  );
}