const initialState = []

const toggleUser = (state = initialState, action) => {
  let nextState
  let index
  switch (action.type) {
    case 'ADD_USER':
      nextState = [...action.value]
      break;
    case 'ADD_USER_ONE':
      nextState = [
        {
          ...action.value
        },
        ...state
      ]
      break;
    case 'UPDATE_USER':
      nextState = state
      index = state.findIndex((item) => item.userId === action.value.userId)
      if (index != -1) {
        nextState[index] = {
          ...nextState[index],
          ...action.value
        }
      } else {
        nextState = [...action.value, ...nextState]
      }
      break;
    case 'DELETE_USER':
      nextState = state.filter((item)=>item.userId!=action.value.id)
      break;
    default:
      return state
      break;
  }
  return nextState || state
}

export {toggleUser}