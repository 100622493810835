//import { formatDate } from "../../function/dateFunction";
import server from "../../server";

const postRequest = async(values,uri, token) => {
  const getPromise = () => new Promise((resolve, reject) => {
    fetch(server + uri, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body:JSON.stringify(values)
    }).then(async(res) => {
      return {
        statusCode: res.status,
        data : await res.json()
      }
    }).then((data) => {
      resolve(data);
    });
  });
  let result = await getPromise();
  return result;
};

const postImgRequest = async(values,uri, token) => {
  const getPromise = () => new Promise((resolve, reject) => {
    fetch(server + uri, {
      method: 'POST',
      headers: {
        
        'Authorization': `Bearer ${token}`
      },
     body: values
    }).then(async(res) => {
      return {
        statusCode: res.status,
        data : await res.text()
      }
    }).then((data) => {
      resolve(data);
    });
  });
  let result = await getPromise();
  return result;
};
export {postRequest,postImgRequest}